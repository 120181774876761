import React, { useEffect, useState } from "react";
import "./TrainerMainSection.css";
import TrainerAnimatedBackground from "../TrainerAnimatedBackground/TrainerAnimatedBackground";
import CalendarComponent from "../CalendarComponent/CalendarComponent";
import UpcomingClasses from "../UpcomingClasses/UpcomingClasses";
import DashboardCards from "../DashboardCards/DashboardCards";
import { useCurrentTrainer } from "../../hooks/useCurrentTrainer";
import SpinnerMini from "../Spinner/SpinnerMini";
import { getAttendence } from "../../config/restApiAuth";
import { useQuery } from "@tanstack/react-query";

function TrainerMainSection() {
  const {
    isLoading: isTrainerLoading,
    error,
    schedules,
    name,
    trainerId,
  } = useCurrentTrainer();

  let filterValue = "90"; // Default to "30" if not present

  // Prepare payload dynamically after trainers data is available
  const attendenceReqestPayload = [
    {
      idType: "trainerId",
      idValue: trainerId,
      duration: `${filterValue}days`,
    },
  ];

  // Fetch attendance data based on the derived payload
  const {
    isLoading: isAttendenceLoading,
    data: attendence,
    error: errorLoadingAttendence,
  } = useQuery({
    queryKey: ["getAttendence", filterValue],
    queryFn: () => getAttendence({ attendenceReqestPayload }),
    enabled: !!attendenceReqestPayload?.length, // Only fetch if payload is available
    // staleTime: (1 / 120) * 60 * 60 * 1000,s // 5 mins
  });

  const next24Hours = new Date() + 24 * 60 * 60 * 1000;
  const now = new Date().getTime();
  const dayOfWeek = new Date().getDay();

  //1) FILTERING SCHEDULES BASED ON is_booked= true
  let bookedSchedules = schedules?.filter((s) => s.is_booked);

  //2_ FILTER THE UPCOMING MEETINGS FROM LAST 30 MIN FROM NOW TILL --
  let newScheduleArray = [];
  newScheduleArray = bookedSchedules
    ?.filter((classItem) => {
      let startTime;
      const date = new Date();

      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
      // console.log(formattedDate);
      // 3) CHANGE START DATE TO TODAY'S DATE UNTILL END DATE IS PASSED.(FOR RECURRING MEETINGS)
      if (new Date().getTime() < new Date(classItem.end_date).getTime()) {
        startTime = `${formattedDate}T${classItem.start_time}`;
      }

      const meetingStartTime = new Date(startTime).getTime();
      // console.log(
      //   meetingStartTime >= now - 0.5 * 60 * 60 * 1000,
      //   "meetingStartTime"
      // );
      return meetingStartTime >= now - 30 * 60 * 1000;
    })
    .sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

  // const students = schedules
  //   .filter((classItem) => {
  //     if (!classItem.is_booked) return false;

  //     const startDate = new Date(classItem.start_date);
  //     const endDate = new Date(classItem.end_date);

  //     return new Date() >= startDate && new Date() <= endDate;
  //   })
  //   .flatMap((classItem) => classItem.students);

  const uniqueStudents = new Set();

  schedules?.forEach((cls) => {
    if (
      cls.is_booked &&
      cls.start_date &&
      cls.end_date &&
      new Date(cls.start_date) <= new Date() &&
      new Date(cls.end_date) >= new Date()
    ) {
      cls.students?.forEach((student) => uniqueStudents.add(student));
    }
  });

  const asssignedStudents = Array.from(uniqueStudents);

  return (
    <>
      {!isTrainerLoading && (
        <div className="student-main-section">
          <div className="student-main-container">
            <div className="heading-bell-container">
              <p className="student-welcome-heading">Welcome! {name}</p>
              <div className="profile-notification-container">
                <p className="bell-icon material-symbols-outlined">
                  notifications
                </p>
              </div>
            </div>
            <div className="student-upcoming-events">
              <TrainerAnimatedBackground
                nearestClass={
                  dayOfWeek === 4 || dayOfWeek === 6
                    ? {}
                    : newScheduleArray?.[0]
                }
              />
            </div>
            <div className="Slot-calendar-section">
              <div className="calendar-and-table">
                <div className="Basic-section">
                  <UpcomingClasses
                    upcomingClasses={
                      dayOfWeek === 4 || dayOfWeek === 6
                        ? []
                        : newScheduleArray?.slice(1, 4)
                    }
                  />
                  <DashboardCards
                    asssignedStudents={asssignedStudents}
                    attendence={attendence}
                  />
                </div>
                <div className="calendar-section">
                  <CalendarComponent upcomingClasses={bookedSchedules} />
                </div>
              </div>
            </div>
            <div className="upload-view-assignment">
              <div className="pending-assignment-section"></div>
              <div className="upload-pending-assignment"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TrainerMainSection;
