import React from "react";
import "./DashboardCards.css";
import { Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function DashboardCards({ asssignedStudents, attendence }) {
  const navigate = useNavigate();
  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className="dashboard-cards-container"
    >
      <div style={{ display: "flex", gap: "20px" }} className="card-section-1">
        <div className="card-item" onClick={() => navigate("/students")}>
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h2" className="card-title">
                Assigned Students
              </Typography>
              <Typography variant="h2" className="card-count">
                {asssignedStudents?.length}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className="card-item">
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h2" className="card-title">
                Classes Taught
              </Typography>
              <Typography variant="h2" className="card-count">
                {attendence?.records[0]?.count}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* <div className="card-section-2">
        <div
          className="card-item"
          onClick={() => navigate("/assignmentmanagement")}
        >
          <Card className="dashboard-card create-assignment">
            <CardContent>
              <Typography variant="h2" className="card-title">
                Assign a Task
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div> */}
    </div>
  );
}

export default DashboardCards;
