import React from 'react';
import { useTransition, animated, useSpring } from '@react-spring/web';
import './Assessment.css';

const AccordionItem = ({ unit, isActive, toggleUnit }) => {
  const transitions = useTransition(isActive, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 'auto' },
    leave: { opacity: 0, height: 0 },
    config: { tension: 250, friction: 25 },
  });

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={() => toggleUnit(unit.id)}>
        <h3>{unit.title}</h3>
        <div
          className="dropdown-icon"
          style={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
          ▼
        </div>
      </div>
      {transitions((styles, item) =>
        item ? (
          <animated.div style={styles} className="accordion-body">
            {unit.assessments.map((assessment) => (
              <div key={assessment.id} className="assessment-row">
                <span className="assessment-title">{assessment.title}</span>
                <AnimatedButton link={assessment.link} />
              </div>
            ))}
          </animated.div>
        ) : null
      )}
    </div>
  );
};

const AnimatedButton = ({ link }) => {
  const [hover, setHover] = React.useState(false);
  const animationProps = useSpring({
    transform: hover ? 'scale(1.1)' : 'scale(1)',
    boxShadow: hover
      ? '0px 4px 12px rgba(0,0,0,0.2)'
      : '0px 2px 6px rgba(0,0,0,0.1)',
    config: { tension: 300, friction: 10 },
  });

  return (
    <animated.a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="take-assessment-button"
      style={animationProps}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      Take Assessment
    </animated.a>
  );
};

export default AccordionItem;
