import React from 'react'
import Assessment from '../../components/Assessment/Assessment'
import { Sidebar } from '../../components/Sidebar/Sidebar'

const AssessmentPage = () => {
  return (
    <div style={{display:'flex'}} className='assessment-page' >
        <Sidebar />

        <Assessment />
      
    </div>
  )
}

export default AssessmentPage
