import { API_BASE_URL, TIMEOUT_SEC } from "./constant.js";

const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} second`));
    }, s * 1000);
  });
};

export async function userLogin({ email, password }) {
  try {
    const url = `${API_BASE_URL}login`;
    const fetchAdminLogin = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
      credentials: "include", // Send cookies along with the request
    });
    const res = await Promise.race([fetchAdminLogin, timeout(TIMEOUT_SEC)]);

    const data = await res.json();
    console.log(data);
    return data;
  } catch (err) {
    //
    console.log(err);
    throw new Error("Invalid email or pass");
  }
}
export async function getCurrTrainer({ trainerId }) {
  try {
    const url = `${API_BASE_URL}currentTrainer`;
    const currTrainer = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        trainerId,
      }),
      credentials: "include", // Send cookies along with the request
    });
    const res = await Promise.race([currTrainer, timeout(TIMEOUT_SEC)]);

    const data = await res.json();
    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
}
export async function getCurrentUser() {
  try {
    //
    const uri = `${API_BASE_URL}me`;
    const registerUser = await fetch(uri, {
      method: "GET",
      credentials: "include",
    });
    const data = await registerUser.json();
    console.log(data);
    return data;
  } catch (error) {
    //
    throw new Error(error.message);
  }
}

export async function logoutUser() {
  try {
    //
    const uri = `${API_BASE_URL}logout`;
    const res = await fetch(uri, {
      method: "GET",
      credentials: "include",
    });
    const data = await res.json();
    return data;
  } catch (error) {
    //
    throw new Error("Unable to logout User.");
  }
}

export async function getAssignedStudents({ students }) {
  try {
    const url = `${API_BASE_URL}schedules/getAssignedStudents`;
    const assignedStudents = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        students,
      }),
      credentials: "include",
    });
    const res = await Promise.race([assignedStudents, timeout(TIMEOUT_SEC)]);

    const data = await res.json();
    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
}
export async function getAssignments({ studentId }) {
  try {
    //
    const uri = `${API_BASE_URL}assignments/getStudentAssignments/${studentId}`;
    const assignments = await fetch(uri, {
      method: "GET",
      credentials: "include",
    });
    const data = await assignments.json();
    console.log(data);
    return data;
  } catch (error) {
    //
    throw new Error(error.message);
  }
}
export async function ScheduleDemoForm({
  student_email,
  trainer_email,
  name,
  startTime,
  accessToken,
}) {
  try {
    // const accessToken =
    const url = `${API_BASE_URL}scheduleDemo`;
    const registerUser = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        trainer_email,
        name,
        student_email,
        startTime,
        accessToken,
      }),
      credentials: "include", // Send cookies along with the request
    });
    const res = await Promise.race([registerUser, timeout(TIMEOUT_SEC)]);

    const data = await res.json();
    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function markAttendence({ student, trainerId }) {
  try {
    const url = `${API_BASE_URL}schedules/markAttendence`;
    const markAttendence = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ trainerId, studentId: student._id }),
      credentials: "include", // Send cookies along with the request
    });
    const res = await Promise.race([markAttendence, timeout(TIMEOUT_SEC)]);

    const data = await res.json();

    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function markAbsent({ student, trainerId }) {
  try {
    const url = `${API_BASE_URL}schedules/markabsent`;
    const markAbsent = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ trainerId, studentId: student._id }),
      credentials: "include", // Send cookies along with the request
    });
    const res = await Promise.race([markAbsent, timeout(TIMEOUT_SEC)]);

    const data = await res.json();

    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
}

export async function getAttendence({ attendenceReqestPayload }) {
  try {
    const url = `${API_BASE_URL}schedules/getAttendence`;
    const registerUser = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ attendenceReqestPayload }),
      credentials: "include", // Send cookies along with the request
    });
    const res = await Promise.race([registerUser, timeout(TIMEOUT_SEC)]);

    const data = await res.json();
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
}
