import React, { useState } from 'react';
import AccordionItem from './AccordionItem';
import './Assessment.css';

const Assessment = () => {
  const staticCourses = [
    {
      id: "A1",
      title: "A1",
      assessments: [
        {
          id: "A1_Test1",
          title: "Test 1 (Units 1-4)",
          link: "https://accentaura.com/courses/A1/A1_Test1",
        },
        {
          id: "A1_Test2",
          title: "Test 2 (Units 5-8)",
          link: "https://accentaura.com/courses/A1/A1_Test2",
        },
        {
          id: "A1_Test3",
          title: "Test 3 (Units 9-12)",
          link: "https://accentaura.com/courses/A1/A1_Test3",
        },
        {
          id: "A1_Test4",
          title: "Test 4 (Units 13-18)",
          link: "https://accentaura.com/courses/A1/A1_Test4",
        },
      ],
    },
    {
      id: "A2",
      title: "A2",
      assessments: [
        {
          id: "A2_Test1",
          title: "Test 1 (Units 1-4)",
          link: "https://accentaura.com/courses/A2/A2_Test1",
        },
        {
          id: "A2_Test2",
          title: "Test 2 (Units 5-8)",
          link: "https://accentaura.com/courses/A2/A2_Test2",
        },
        {
          id: "A2_Test3",
          title: "Test 3 (Units 9-12)",
          link: "https://accentaura.com/courses/A2/A2_Test3",
        },
        {
          id: "A2_Test4",
          title: "Test 4 (Units 13-18)",
          link: "https://accentaura.com/courses/A2/A2_Test4",
        },
      ],
    },
    {
      id: "B1",
      title: "B1",
      assessments: [
        {
          id: "B1_Test1",
          title: "Test 1 (Units 1-4)",
          link: "https://accentaura.com/courses/B1/B1_Test1",
        },
        {
          id: "B1_Test2",
          title: "Test 2 (Units 5-8)",
          link: "https://accentaura.com/courses/B1/B1_Test2",
        },
        {
          id: "B1_Test3",
          title: "Test 3 (Units 9-12)",
          link: "https://accentaura.com/courses/B1/B1_Test3",
        },
        {
          id: "B1_Test4",
          title: "Test 4 (Units 13-18)",
          link: "https://accentaura.com/courses/B1/B1_Test4",
        },
      ],
    },
    {
      id: "B2",
      title: "B2",
      assessments: [
        {
          id: "B2_Test1",
          title: "Test 1 (Units 1-4)",
          link: "https://accentaura.com/courses/B2/B2_Test1",
        },
        {
          id: "B2_Test2",
          title: "Test 2 (Units 5-8)",
          link: "https://accentaura.com/courses/B2/B2_Test2",
        },
        {
          id: "B2_Test3",
          title: "Test 3 (Units 9-12)",
          link: "https://accentaura.com/courses/B2/B2_Test3",
        },
        {
          id: "B2_Test4",
          title: "Test 4 (Units 13-18)",
          link: "https://accentaura.com/courses/B2/B2_Test4",
        },
      ],
    },
  ];

  const [activeCourse, setActiveCourse] = useState(null);

  const toggleCourse = (courseId) => {
    setActiveCourse(activeCourse === courseId ? null : courseId);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterOption, setFilterOption] = useState('All');

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectOption = (option) => {
    setFilterOption(option);
    setDropdownOpen(false);
  };

  return (
    <div className="page-container">
      <div className="header">
        <div className="header-left">Assessment</div>
        <div className="header-right">
          <div className="dropdown">
            {/* <button className="dropdown-toggle" onClick={toggleDropdown}>
              Filter: {filterOption} <span className={`arrow ${dropdownOpen ? 'open' : ''}`}>▼</span>
            </button> */}
            {dropdownOpen && (
              <ul className="dropdown-menu">
                <li onClick={() => selectOption('All')}>All</li>
                <li onClick={() => selectOption('Completed')}>Completed</li>
                <li onClick={() => selectOption('Pending')}>Pending</li>
              </ul>
            )}
          </div>
        </div>
      </div>

      <div className="accordion-container">
        {staticCourses.map((course) => (
          <AccordionItem
            key={course.id}
            unit={course} // Here, the 'course' object is used as the unit
            isActive={activeCourse === course.id}
            toggleUnit={toggleCourse}
          />
        ))}
      </div>
    </div>
  );
};

export default Assessment;
