import React from "react";
import "./AssignmentLibraryCard.css";
import { useNavigate } from "react-router-dom";

function AssignmentLibraryCard({ studentId, assignment }) {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams({
    assignmentId: assignment._id,
    studentId,
  }).toString();

  navigate(`/target-page?${queryParams}`);

  return (
    <div className="assignment-library-card">
      <div className="assignment-library-card-container">
        <div className="assignment-library-card-header">
          <p>{assignment.assignment_title}</p>
        </div>
        <div className="assignment-library-card-body">
          <p>{assignment.assignment_description}</p>
          <button
            onClick={() => navigate(`/assignmentview?${queryParams}`)}
            className="view-assignment-button"
          >
            View Assignment
          </button>
        </div>
      </div>
    </div>
  );
}

export default AssignmentLibraryCard;
